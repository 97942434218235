var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PageHeader',{attrs:{"section":"products"}}),_vm._m(0),_c('div',{staticClass:"container"},[_c('LightBox',{attrs:{"gallery-mode":false,"get-footer":_vm.getFooter,"get-header":_vm.getHeader,"get-url":_vm.getUrl,"get-alt":_vm.getAlt,"image-index":_vm.getImageIndex(),"images":this.$data.doorstyleImages,"visible":_vm.visible},on:{"on-close":_vm.OnClose,"index-change":_vm.changeIndex}}),(_vm.doorstyleImages)?_c('div',{staticClass:"row gallery-grid"},_vm._l((_vm.doorstyleImages),function(image,i){return _c('div',{key:image.id,staticClass:"col-lg-2 col-md-2 col-4 my-3"},[_c('div',{staticClass:"card"},[_c('img',{staticClass:"card-img ",attrs:{"src":image.Url,"data-type":"image","data-toggle":"lightbox","data-gallery":"wdoor-gallery","data-remote":image.Url,"data-title":image.Metadata.door,"data-footer":'Door Style: '
                  + image.Metadata.door
                  + '<br>Finish: '
                  + image.Metadata.finish
                  +'<br>Glaze: '
                  + image.Metadata.glaze
                  + '<br>Wood Species: '
                  + image.Metadata.species,"alt":"Essex door style with American Poplar wood"},on:{"click":function($event){return _vm.imageClicked(i)}}})])])}),0):_vm._e()],1),_c('PageFooter')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container d-xs-block d-sm-block d-md-none mt-0 mb-3"},[_c('h3',{staticClass:"text-center"},[_vm._v("Products")])])}]

export { render, staticRenderFns }