













































import { AzureStorageBlob } from '@wwinc/azure-storage';
import { factory          } from '@/logging';

import Vue    from 'vue';
import PageHeader from '@/components/products/pageheader.vue';
import PageFooter from '@/components/pagefooter.vue';

import LightBox from '../standalone/lightbox.vue';
const logger = factory.getLogger('component.product.rangehood');

export default Vue.extend({
  name: 'rangehoods',
  props: {
    section: String
  },
  components: {
    PageHeader,
    PageFooter,
    LightBox
  },
  created(): void {
    logger.trace('RangeHoods component has been created.');
  },
  methods: {

    changeLightBoxIndex(index: number) {
      this.lightBoxIndex = index;
    },

    galleryModeImageClicked(index: number) {
      this.getHoodsInSameGroupArray(this.uniqueRangehoods[index]);
      this.lightBoxIndex = 0;
      this.galleryModeVisible = true;

    },
    getHeader(image: any) {
      return this.selectedHoodGroupName;
    },
    getFooter (image: any) {
      return image.footer;
    },
    getUrl(image: any)  {
      return image.Url;
    },
    getImageIndex() {
      return this.lightBoxIndex;
    },
    getImages() {
      return (this as any).$data.images;
    },
    OnGalleryClose() {
      this.galleryModeVisible = false;
    },
    getAlt(image: any) {
      return image.alt;
    },

    getCenterUniqueHoodsArray(images: AzureStorageBlob[]): AzureStorageBlob[]{
      let groupedImagesArray:    string[]           = [];
      let azureStorageBlobArray: AzureStorageBlob[] = [];

      images.forEach(implement=>{
        let groupName = implement.Name.substring(
              0,
              implement.Name.indexOf('/')
        );

        if (!groupedImagesArray.includes(groupName)) {
          groupedImagesArray.push(groupName);
          azureStorageBlobArray.push(implement);
        }
      });
      return azureStorageBlobArray;
    },

    getHoodsInSameGroupArray(image: AzureStorageBlob){
      this.selectedHood = image;
      let azureStorageBlobArray: AzureStorageBlob[] = [];

      this.selectedHoodGroupName = image.Name.substring(
        0,
        image.Name.indexOf('/')
      );

      this.allRangehoods.forEach(instance=>{
        let thisImagesGroup= instance.Name.substring(
              0,
              instance.Name.indexOf('/')
        );

        if (this.selectedHoodGroupName === thisImagesGroup) {
          azureStorageBlobArray.push(instance);
        }
      });

      this.selectedHoodGroup = azureStorageBlobArray;
    },
  },
  computed: { },
  data() {
    return {
      lightBoxIndex: 0,
      galleryModeVisible: false,
      navbar:                'products',
      selectedHoodGroup:     [ ] as AzureStorageBlob[ ],
      selectedHoodGroupName: '',
      selectedHood:          { } as AzureStorageBlob,
      rangehoodGrouped:      [ ],
      allRangehoods:         [ ] as AzureStorageBlob[ ],
      uniqueRangehoods:      [ ] as AzureStorageBlob[ ]
    };
  },
  mounted(){
    (this as any).$imageService!.getImages(
        'aspect-images-products-rangehoods'
    ).subscribe(
      (images: any)=>{
        this.allRangehoods    = images;
        this.uniqueRangehoods = this.getCenterUniqueHoodsArray(images);
      }
    );
  },
  inject: ['$imageService'],
});
