



























































































import Vue              from 'vue';

import LightBox from './standalone/lightbox.vue';
import {factory} from '@/logging';

import { AzureStorageBlob } from '@wwinc/azure-storage';
import PageHeader from '@/components/products/pageheader.vue';

const logger = factory.getLogger('Gallery component.gallery');

export default Vue.extend({
  name: 'gallery',
  props: {
    section: String
  },
  components: {
    PageHeader,
    LightBox
  },
  created(): void {
    logger.trace('component has been created.');
  },
  methods: {

    changeIndex(index: number) {
      this.index = index;
    },
    imageClicked(index: number) {
      this.index = index;
      this.visible = true;
    },
    getHeader(image: AzureStorageBlob) {
      return '';
    },
    getFooter (image: AzureStorageBlob) {
      return image.Metadata.html;
    },

    getUrl(image: AzureStorageBlob)  {
      return  image.Url;
    },
    getImageIndex() {
      return this.index;
    },
    getImages() {
      return (this as any).$data.images;
    },
    OnClose() {
      this.visible = false;
    },
    getAlt(image: any) {
      return image.alt;
    },
    /**
     * Changes the the selected tab.
     * @param id The id of the tab.
     */
    changeSelected(id: any) {
      logger.trace(`changeSelected(id = ${id})`);

      let searching = true;
      //set active tab class to active
      for(const option of this.availableOptions){
        if(option.id == id) {
          logger.trace(`Clicked tab found by id`);
          option.class = 'active';
          searching = false;
        } else {
          option.class = '';
        }
      }

      if(searching) {
        logger.trace(`Could not find the id of the clicked tab`);
      }

      //change images when tab changes
      switch (id) {
        case 1:
          logger.trace(`Switching to kitchen images`);
          this.images = this.kitchenImages;
          break;
        case 2:
          logger.trace('Switching to bath images');
          this.images = this.bathImages;
          break;
        case 3:
          logger.trace('Switching to utility images');
          this.images = this.utilityImages;
          break;
        case 4:
          logger.trace('Switching to other images');
          this.images = this.otherImages;
          break;
      }
    }
  },
  computed: {
  },
  data() {
    return {
      index: 0,
      visible: false,
      navbar:        'gallery',
      kitchenImages: [] as AzureStorageBlob[],
      bathImages:    [] as AzureStorageBlob[],
      utilityImages: [] as AzureStorageBlob[],
      otherImages:   [] as AzureStorageBlob[],
      images:        [] as AzureStorageBlob[],
      // Set array of cabinet type options for gallery
      availableOptions: [
        {
          'id':    1,
          'type':  'Kitchen',
          'class': 'active'
        },
        {
          'id':     2,
          'type':  'Bath',
          'class': ''
        },
        {
          'id':    3,
          'type':  'Utility',
          'class': ''
        },
        {
          'id':    4,
          'type':  'Other',
          'class': ''
        }
      ],
    };
  },
  inject: [
    '$imageService'
  ],
  mounted() {
    (this as any).$imageService!.getImages(
        'aspect-images-gallery-kitchen'
    ).subscribe((images: any) => {
      this.kitchenImages = images;
      // Once the kitchen images have loaded, display them.
      this.changeSelected(1);

    });

    (this as any).$imageService!.getImages(
        'aspect-images-gallery-bath'
    ).subscribe((images: any) => {
      this.bathImages = images;
    });

    (this as any).$imageService!.getImages(
        'aspect-images-gallery-utility'
    ).subscribe((images: any) => {
      this.utilityImages = images;
    });

    (this as any).$imageService!.getImages(
        'aspect-images-gallery-other'
    ).subscribe((images: any) => {
      this.otherImages = images;
    });
  }
})
